import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

export default function Error404() {
  return (
    <section className="flex flex-wrap align-middle" >
        <div className='w-full md:w-1/2 text-left mt-2 md:mt-24'>
    <Image  
    src="/_img/tape.png" alt="cassete tangled tape" layout="responsive" width={800} height={533} />
        </div>
    
        <div className="w-full md:w-1/2 text-center mt-0 md:mt-48">
          <span className="text-xs font-bold text-red-800 uppercase">Eroare 404</span>
          <h2 className="mb-2 text-4xl lg:text-5xl font-bold font-heading">Pagina inexistentă</h2>
          <p className="mb-6 text-sm text-gray-500 leading-loose">
            Ne pare rău, însă această pagină nu există.
          </p>
          <div className="flex flex-wrap justify-center">
            <Link href = "/" title='salt la pagina principala'>
            <a className="px-6 py-2 mr-4 bg-gray-500 hover:bg-gray-600 text-sm leading-loose text-gray-50 font-semibold rounded"> 
            DU-MĂ LA PAGINA PRINCIPALĂ</a>
            </Link>
          {/* <a className="px-6 py-2 border hover:border-gray-400 text-sm font-semibold leading-loose rounded" href="#">Try Again</a> */}
          </div>
        </div>
  </section>
  )
   
  
}
